.app_header {
    padding: 16px 0px;
    // border-bottom: 1px solid #2f2b2b;

    .app_logo {
        margin-right: 60px;

        img {
            width: 30px;
        }
    }

    .navbar-expand-lg {
        .navbar-nav {

            .nav-link,
            a {
                color: var(--light-color);
                font-weight: 500;
                font-size: 14px;
                text-decoration: none;
                padding: 10px;
            }
        }
    }

    .header_btn_wrap {
        .header_btn {
            gap: 8px;
            border: 1px solid rgba(255, 255, 255, 0.15);
            cursor: pointer;
            display: flex;
            padding: 8px 12px;
            max-width: 200px;
            background: rgba(255, 255, 255, 0.10);
            transition: all 150ms;
            align-items: center;
            border-radius: 8px;
            justify-content: space-between;
            color: #fff;
            font-size: 14px;

            span {
                vertical-align: middle;
            }
        }
    }

    button {
        &:focus {
            box-shadow: none;
        }
    }

    .dropdown_wrap {
        .header_btn {
            gap: 8px;
            border: 1px solid rgba(255, 255, 255, 0.15);
            cursor: pointer;
            display: flex;
            padding: 8px 12px;
            max-width: 200px;
            background: rgba(255, 255, 255, 0.10);
            transition: all 150ms;
            align-items: center;
            border-radius: 8px;
            justify-content: space-between;
            color: #fff;
            font-size: 14px;

            span {
                vertical-align: middle;
            }
        }

        .dropdown {

            .btn-success {
                gap: 8px;
                border: 1px solid rgba(255, 255, 255, 0.15);
                cursor: pointer;
                display: flex;
                padding: 8px 12px;
                max-width: 200px;
                background: rgba(255, 255, 255, 0.10);
                transition: all 150ms;
                align-items: center;
                border-radius: 8px;
                justify-content: space-between;
                color: #fff;
                font-size: 14px;

                span {
                    vertical-align: middle;
                }
            }

            .dropdown-menu {
                left: -220px;
                background-color: rgba(255, 255, 255, 0.1);
                border: 1px solid #39424c;
                border-radius: 12px;
                width: 320px;
                box-shadow: 0px 4px 16px -1px rgba(0, 0, 0, .08), 0px 0px 8px -4px rgba(0, 0, 0, .12);
                overflow: hidden;
                padding: 10px 15px;
                color: #fff;

                .user_profile_wrap {
                    margin-bottom: 15px;

                    .user_profile {
                        text-align: center;

                        img {
                            width: 80px;
                            border-radius: 50%;
                            height: 80px;
                        }
                    }

                    h4 {
                        font-size: 18px;
                        text-align: center;
                        color: var(--light-color);

                        .text_copied {
                            font-size: 12px;
                            background: var(--secondary-color);
                            padding: 4px;
                            border-radius: 5px;
                            box-shadow: 0px 0px 10px 0px #3a3a3b;
                            position: relative;
                            top: -23px;
                            left: -35px;
                        }

                        span {
                            cursor: pointer;
                        }
                    }
                }

                .dropdown-item {
                    padding: 6px 8px;
                    color: var(--text-color);

                    &:hover {
                        background-color: var(--button-color);
                    }

                    svg {
                        font-size: 20px;
                        margin-right: 5px;
                        vertical-align: middle;
                    }
                }
            }
        }

        .disconnect_btn {
            gap: 8px;
            border: 1px solid rgba(255, 255, 255, 0.15);
            cursor: pointer;
            display: flex;
            padding: 8px 12px;
            max-width: 200px;
            background: rgba(255, 255, 255, 0.10);
            transition: all 150ms;
            align-items: center;
            border-radius: 8px;
            justify-content: space-between;
            color: #fff;
            font-size: 14px;
        }
    }

    .right_header_wrap {
        display: flex;
    }
}

@media screen and (max-width:991px) {
    .app_header {
        padding: 0px;
    }

    .app_header .header_btn_wrap .header_btn {
        margin-bottom: 5px;
    }

    .app_header .right_header_wrap {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
    }

}

@media screen and (max-width:380px) {
    .app_header .dropdown_wrap .dropdown .dropdown-menu {
        left: -80px;
        width: 211px;
    }
}