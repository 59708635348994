@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800;900&display=swap');
@import "./common/color_variable";

body {
    margin: 0px;
    padding: 0px;
    line-height: 1.7;
    font-weight: 400;
    background-color: black !important;
    background: radial-gradient(60.47% 60.47% at 59.06% 42.55%, #211156 0%, #090316 100%) no-repeat;
    font-family: 'Rubik', sans-serif;
    width: 100vw;
    height: 100vh;
    display: flex;
}

* {
    letter-spacing: 0.5px;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Rubik', sans-serif;
}

ol,
li,
a,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
    padding: 0px;
    font-family: 'Rubik', sans-serif;
}

#root {
    display: flex;
    flex: 1;
}

.sidebar {
    background: rgba(255, 255, 255, 0.10);
    border-right: 1px solid rgba(255, 255, 255, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    width: 200px;

    .logo {
        width: 80%;
        margin: 0 auto;
    }

    .bottom {
        display: flex;
        flex-direction: column;
        gap: 16px;

        a {
            color: rgba(255, 255, 255, 0.8);
            opacity: 0.85;
            font-size: 16px;
            text-align: center;
            font-weight: 400;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            &:hover {
                color: #FB3BC6;
            }
        }



    }

    ul {
        padding: 0px;
        display: flex;
        // background: var(--secondary-color);
        justify-content: space-between;
        flex-direction: column;
        gap: 24px;
        margin: 0;
        margin-top: 32px;

        li {
            list-style: none;
            width: 100%;
            text-align: center;
            border-radius: 12px;

            a {
                text-align: center;
                width: 100%;
                border-radius: 8px;
                padding: 8px;
                text-decoration: none;
                display: inline-block;
                font-weight: 500;
                font-size: 14px;
                color: var(--text-color);
                position: relative;

                color: rgba(255, 255, 255, 0.8);
                width: 100%;
                cursor: pointer;
                height: 40px;
                display: flex;
                background: rgba(255, 255, 255, 0.10);
                align-items: center;
                border-radius: 8px;
                justify-content: center;

                &:hover {
                    border: 1px solid hsl(247, 100%, 60%);
                    opacity: 0.85;
                }

                &.active {
                    box-shadow: 0 0 8px rgba(20, 23, 26, 0.0784313725), 0 0 4px rgba(20, 23, 26, 0.0392156863);
                    border: 1px solid #4A31FF;
                    background: #4A31FF;
                }

            }
        }
    }
}

.main_wrap {
    display: flex;
    flex: 1;

    .bridge_wrap {
        width: 100%;
        max-width: 500px;
        margin: 0px auto;

        ul {
            padding: 0px;
            display: flex;
            // background: var(--secondary-color);
            justify-content: space-between;
            gap: 8px;
            border-radius: 10px;

            li {
                list-style: none;
                width: 100%;
                text-align: center;
                border-radius: 12px;



                a {
                    text-align: center;
                    padding: 8px;
                    text-decoration: none;
                    font-weight: 500;
                    font-size: 14px;
                    position: relative;

                    color: rgba(255, 255, 255, 0.8);
                    width: 100%;
                    cursor: pointer;
                    height: 40px;
                    display: flex;
                    background: rgba(255, 255, 255, 0.10);
                    align-items: center;
                    border-radius: 8px;
                    justify-content: center;

                    &:hover {
                        opacity: 0.85;
                        border: 1px solid #4A31FF;
                    }

                    &.active {
                        box-shadow: 0 0 8px rgba(20, 23, 26, 0.0784313725), 0 0 4px rgba(20, 23, 26, 0.0392156863);
                        color: white;
                        background-color: var(--button-color);
                        border: 1px solid #4A31FF;
                        color: rgba(255, 255, 255, 0.8);
                        width: 100%;
                        cursor: pointer;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        border-radius: 8px;
                        justify-content: center;
                        background: #4A31FF;
                    }

                }
            }
        }
    }

}

.pagination_wrap {
    .pagination {
        margin: 0px;
        justify-content: start !important;

        .page-item {
            &.active {
                .page-link {
                    border: 1px solid #FBCBA7;
                    border-radius: 5px;
                    padding: 0px 11px;
                }
            }

            .page-link {
                background: var(--secondary-color);
                color: #FBCBA7;
                border-color: var(--secondary-color);
                padding: 1px 10px;
                font-size: 12px;

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: var(--text-color) !important;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: var(--text-color) !important;
    opacity: 1;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: var(--text-color) !important;
    opacity: 1;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--text-color) !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--text-color) !important;
}

::placeholder {
    /* Most modern browsers support this now. */
    color: var(--text-color) !important;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    left: 50%;
    transform: translate(-50%);
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #4a31ff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}