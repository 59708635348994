:root {
    // --primary-color: #EDE4C5;
    // --secondary-color: #111827;
    // --button-color: transparent;
    // --light-color: #fff;
    // --dark-color: #000;
    // --text-color: #94A3B8;
    // --card-color: #002942;

    // --primary-color: #EDE4C5;
    --primary-color: linear-gradient(90deg, #B37A56 0%, #FBCBA7 32.29%, #C38A79 71.35%, #945B3B 95.31%);
    --secondary-color: #fff;
    --button-color: transparent;
    --light-color: #fff;
    --dark-color: #000;
    --text-color: #94A3B8;
    --card-color: transparent;
}