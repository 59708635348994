.account_withdraw_table {
    table {
        background-color: #fff;
        border-radius: 12px;
        margin-top: 12px;

        tbody,
        td,
        tfoot,
        th,
        thead,
        tr {
            border-style: none;
            color: #000;
            padding: 16px;
        }

        tbody {
            tr {
                td {
                    font-size: 12px;
                    color: #000;
                }

                .withdraw_inner_btn {
                    background: transparent;
                    font-size: 12px;
                    padding: 0px 16px;
                    font-weight: 600;
                    border-radius: 3px;
                    float: right;
                    min-width: 68px;

                    .spinner-border {
                        height: 15px;
                        width: 15px;
                    }

                    &:hover {
                        color: var(--dark-color);
                    }
                }
            }

            tr td {
                background-color: transparent !important;
                box-shadow: none;

                a {
                    text-decoration: none;
                    color: #000;
                }
            }
        }

        th {
            background-color: transparent;
        }
    }

}